<template>
  <div>
    <v-layout wrap justify-end>
      <v-flex xs12>
        <v-menu :close-on-content-click="false" offset-y left v-model="menud">
          <template v-slot:activator="{ attrs, on }">
            <v-layout wrap justify-center v-bind="attrs" v-on="on">
              <v-flex xs12 text-center align-self-center>
                <v-icon title="Account Info" color="#ffffff" size="25">
                  mdi-account-switch-outline
                </v-icon>
              </v-flex>
            </v-layout>
          </template>
          <v-card tile flat :elevation="0" max-width="250px">
            <v-layout wrap justify-center py-4>
              <v-flex pt-4 xs12>
                <span
                  style="
                    font-family: kumbhEBold;
                    font-size: 16px;
                    color: #000000;
                  "
                >
                  Switch Account
                </span>
              </v-flex>

              <v-flex xs12 pa-2>
                <v-radio-group dense v-model="position">
                  <v-radio
                    style="font-family: KumbhMedium !important"
                    color="#005f32"
                    v-for="(item, i) in appData"
                    :key="i"
                    :label="item.name"
                    :value="item._id"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex xs12 pt-4>
                <v-btn
                  color="#0000001f"
                  outlined
                  tile
                  small
                  :ripple="false"
                  @click="switchRole"
                >
                  <span
                    style="
                      font-family: kumbhEBold;
                      font-size: 14px;
                      color: #005f32;
                    "
                  >
                    Switch
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-menu>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      user: {},
      positionList: [],
      position:null,
      menud:false,
    };
  },
  computed: {
    appLogin() {
      return this.$store.state.isLoggedIn;
    },
    appUser() {
      return this.$store.state.userData;
    },
    appData() {
      return this.$store.state.exceptPos;
    },
  },
  methods: {
    switchRole() {
      if (!this.position) {
        this.msg = "Please choose a position!";
        this.showSnackBar = true;

        return;
      }
      var userData = {};
      userData["position"] = this.position;
      this.$store.commit("appLoading", true);
      axios({
        method: "POST",
        url: "/employee/switchposition",
        data: userData,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.$store.commit("appLoading", false);
          if (response.data.status) { 
            this.menud=false
            this.$store.commit("userData", response.data.data);
            this.$store.commit("fullData", response.data);

            this.$store.commit("menu", response.data.menus);
            this.$store.commit("loginUser", response.data.token); 
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
          this.position=null
        })
        .catch((err) => {
          this.$store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
