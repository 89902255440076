<template>
  <div id="appNavbar">
    <v-navigation-drawer v-model="sideNav" fixed temporary color="#fffcf5">
      <v-layout wrap>
        <v-flex xs12 text-left py-2 text-uppercase align-self-center>
          <v-list dense v-for="main in appNavItems" :key="main._id">
            <v-layout wrap>
              <v-flex xs12 py-2>
                <v-list-group no-action :value="false" active-class="activated">
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 2px;
                        color: black;
                        cursor: pointer;
                        font-family: kumbhRegular;
                      "
                    >
                      <span class="text-none">
                        {{ main.menu.name }}
                      </span>
                    </v-list-item-title>
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid black"></div>
                  </v-flex>
                  <template v-for="sub in main.submenus">
                    <v-flex xs12 text-left pl-4 :key="sub._id">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="sub.route">
                            <span
                              :style="
                                $route.path == sub.route
                                  ? {
                                      'font-family': 'kumbhEBold',
                                      'font-size': ' 16px',
                                    }
                                  : {
                                      'font-family': 'kumbhMedium',
                                      'font-size': ' 13px',
                                    }
                              "
                              style="
                                color: black;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              <span class="text-none">
                                {{ sub.name }}
                              </span>
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="sub._d">
                      <div style="border-bottom: 1px solid black"></div>
                    </v-flex>
                  </template>
                </v-list-group>
                <!-- <v-list-group v-else> -->
                <!-- <v-list dark dense>
                  <template v-for="(item, i) in appNavItems">
                    <v-flex xs12 pl-4 align-self-center text-left :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                      'font-size': ' 16px',
                                    }
                                  : {
                                      'font-family': 'kumbhEBold',
                                      'font-size': ' 13px',
                                    }
                              "
                              style="
                                font-size: 13px;
                                color: black;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </template>
                </v-list> -->
              </v-flex>
            </v-layout>
            <!-- </v-list-group> -->
          </v-list>
        </v-flex>
      </v-layout>
      <!-- <v-layout wrap justify-center>
        <v-flex
          xs12
          v-for="(item, i) in appNavItems"
          :key="i"
          text-center
          pa-2
          pl-0
          text-none
          align-self-center
        >
          <v-layout wrap justify-center>
            <v-flex xs12 px-0 pl-2 align-self-center text-left>
              <router-link :to="item.route">
                <v-icon>{{ item.icon }}</v-icon>
                <span
                  :style="
                    $route.path == item.route
                      ? {
                          'font-family': 'poppinsbold',
                        }
                      : {
                          'font-family': 'poppinslight',
                        }
                  "
                  style="
                    font-size: 15px;
                    color: black;
                    letter-spacing: 3px;
                    cursor: pointer;
                  "
                >
                  {{ item.name }}
                </span>
              </router-link>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout> -->
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      color="#292626"
      dense
      flat
      height="50px"
      class="hidden-lg-and-up"
    >
      <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
        <v-icon color="white">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/">
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-img
                contain
                height="50px"
                :src="require('./../../public/wtilogo.png')"
              ></v-img>
              <!-- <span
                style="
                  font-family: poppinsbold;
                  font-size: 20px;
                  cursor: pointer;
                  color: #FFCC00;
                "
              >
                Pak’t
              </span> -->
            </v-flex>
          </v-layout>
        </router-link>
      </v-toolbar-title>
    </v-app-bar>
    <v-layout wrap justify-end class="hidden-md-and-down">
      <v-flex lg2 align-self-center>
        <v-card tile height="70px" color="#005f32">
          <router-link to="/">
            <v-img
              contain
              height="60px"
              :src="require('./../../public/wtilogo.png')"
            ></v-img>
          </router-link>
        </v-card>
      </v-flex>
      <v-flex lg10 align-self-center>
        <v-app-bar
          color="#005f32"
          elevation="0"
          height="70px"
          class="hidden-md-and-down"
        >
          <v-layout wrap justify-center fill-height>
            <v-flex xs2 align-self-center>
              <!-- <router-link to="/">
                <v-img
                  contain
                  height="50px"
                  :src="require('./../../public/wtilogo.png')"
                ></v-img>
              </router-link> -->
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs6 lg1 xl1 px-4 text-center align-self-center style="cursor:pointer" v-if="appUser.positionid">
                <div v-if="appUser.positionid.length>1">
                    <SwitchAccount />
                </div>
               
            </v-flex>
            <v-flex xs6 lg1 xl1 px-4 text-center align-self-center style="cursor:pointer">
              <AccountMenu />
            </v-flex>
          </v-layout>
        </v-app-bar>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import AccountMenu from "./../components/accountMenu";
import SwitchAccount from "./../components/switchAccount";
export default {
  components: {
    AccountMenu,
    SwitchAccount
  },
  data() {
    return {
      sideNav: false,
      admin: [{ name: "ADMINS", route: "/Admins" }],
      menus: [
        {
          name: "Dashboard",
          subMenu: [
            {
              name: "Dashboard",
              route: "/",
            },
          ],
        },

        {
          name: "Category",
          subMenu: [
            {
              name: "Category List",
              route: "/CategoryList",
            },
          ],
        },
        {
          name: "Job Title",
          subMenu: [
            {
              name: "Title List",
              route: "/TitleList",
            },
          ],
        },
        {
          name: "Volunteer Title",
          subMenu: [
            {
              name: "Title List",
              route: "/Volunteer",
            },
          ],
        },
        {
          name: " Job Applications",
          subMenu: [
            {
              name: "Applications",
              route: "/Applications",
            },
          ],
        },
        {
          name: "Volunteer Applications",
          subMenu: [
            {
              name: "Volunteer Applications",
              route: "/volunteerApplication",
            },
          ],
        },
        {
          name: "Employee ",
          subMenu: [
            {
              name: "Employee Document",
              route: "/employeedocument",
            },
            {
              name: "Employee List",
              route: "/employeelist",
            },
          ],
        },

        {
          name: "Products",
          subMenu: [
            {
              name: "Products",
              route: "/products",
            },
            {
              name: "Add Product",
              route: "/addProducts",
            },
            {
              name: "Add Product-Type",
              route: "/ProductType",
            },
          ],
        },

        {
          name: "Purchase",
          subMenu: [
            {
              name: "Purchase",
              route: "/purchaseReports",
            },
          ],
        },

        {
          name: "About Us",
          subMenu: [
            {
              name: "About us",
              route: "/aboutUs",
            },
            {
              name: "Video",
              route: "/Video",
            },
            {
              name: "Announcements",
              route: "/Announcements",
            },
          ],
        },
      ],
    };
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
    appMenu() {
      // console.log("menus", store.state.menus);
      return this.$store.state.menus;
    },
    appLogin() {
      return false;
    },
    appType() {
      return this.$store.state.userType;
    },
    appNavItems() {
      return this.$store.state.menus;
    },
    
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: kumbhRegular !important;
}
input::-moz-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: kumbhRegular !important;
}
.searchBox .v-input__control {
  min-height: 10px !important;
}
.searchBox .v-input__control .v-input__slot {
  padding: 0px !important;
}
</style>
